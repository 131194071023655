import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import classnames from 'classnames';
import circle from './image/checkcircle.svg';
import hovercircle from './image/hovercircle.svg';

// import { parseString } from '@helpers';
import style from './Card.module.scss';

const Card = ({
  // isEnterprise,
  // planId,
  title,
  subtitle,
  // name,
  // selectedlawsnumber,
  // oneprivacypriceusd,
  // twoprivacypriceusd,
  // threeprivacypriceusd,
  // oneprivacypriceeur,
  // twoprivacypriceeur,
  // threeprivacypriceeur,
  description,
  // buttonText,
  // buttonLink,
  disabled,
  // currency,
  // isAnnual,
  imgUrl,
  // annualcoefficient,
  // selectedPlans,
  isMobile,
  // enterpriseCondition,
  // enterpriseButtonText,
  // enterpriseButtonLink,
  bottomClarification,
  planBenefits,
  // isStarter,
  isMorePlans,
  toggleBussinessCards,
  checkFeaturesOnCard,
}) => {
  const [colorized, setColorized] = useState(false);
  // const getCost = () => {
  //   // console.log('currency', currency);
  //   // console.log('selectedlawsnumber', selectedlawsnumber);
  //   // console.log('oneprivacypriceusd', oneprivacypriceusd);
  //   // console.log('annualcoefficient', annualcoefficient);
  //   // console.log('isAnnual', isAnnual);
  //   // console.log(bottomClarification, "bottomClarification")

  //   let cost = 0;
  //   if (currency === 'US Dollar $') {
  //     switch (selectedlawsnumber) {
  //       case 1:
  //         cost = oneprivacypriceusd;
  //         break;
  //       case 2:
  //         cost = twoprivacypriceusd;
  //         break;
  //       case 3:
  //         cost = threeprivacypriceusd;
  //         break;
  //     }
  //   } else {
  //     switch (selectedlawsnumber) {
  //       case 1:
  //         cost = oneprivacypriceeur;
  //         break;
  //       case 2:
  //         cost = twoprivacypriceeur;
  //         break;
  //       case 3:
  //         cost = threeprivacypriceeur;
  //         break;
  //     }
  //   }

  //   return isAnnual ? cost * annualcoefficient : cost;
  // };

  // const getPeriod = () => {
  //   return isAnnual ? 'annual' : 'monthly';
  // };

  // const getPlanName = () => {
  //   const planFullName = parseString(title.richText);
  //   const planName = planFullName.split(' ')[0].toLowerCase();

  //   return planName;
  // };

  // const getPlans = () => {
  //   const plansNames = selectedPlans.join(' ').toLowerCase();

  //   return plansNames;
  // };

  return (
    <div
      onMouseEnter={() => setColorized(false)}
      onMouseLeave={() => setColorized(false)}
      className={classnames(style.container, {
        [style.colorized]: colorized,
        [style.colorizedmobile]: isMobile && colorized,
        [style.colorizeddesktop]: !isMobile && colorized,
        [style.disabled]: disabled,
        [style.isMorePlans]: isMorePlans,
      })}
    >
      {isMorePlans && <div className={style.isMorePlansBg}></div>}

      {title.richText[0].text !== 'RESELLER PROGRAM' && (
        <div className={style.title}>
          <img src={imgUrl} width={56} alt="image" />
          <RichText render={title.richText} />
          {/* {!isMorePlans && <hr />} */}
          <div className={style.subtitle}>
            <RichText render={subtitle.richText} />
          </div>
        </div>
      )}

      {title.richText[0].text === 'RESELLER PROGRAM' && (
        <div className={style.titleforOrange}>
          <img src={imgUrl} width={56} alt="image" />
          <RichText render={title.richText} />
          {/* {!isMorePlans && <hr />} */}
          <div className={style.subtitle}>
            <RichText render={subtitle.richText} />
          </div>
        </div>
      )}

      <div className={style.innerWrapper}>
        <div className={style.text}>
          <RichText render={planBenefits.richText} />
        </div>

        <div className={style.wrappperPrice}></div>

        {isMorePlans && (
          <div
            className={`${style.planBenefits} ${style.isMorePlansDescription}`}
          >
            <RichText render={description.richText} />
          </div>
        )}

        {!isMorePlans && (
          <div className={style.bottomText}>
            <div className={style.bottomClarificationColor}>
              <RichText render={bottomClarification?.richText} />
            </div>
            {checkFeaturesOnCard?.richText?.map((item, index) => (
              <>
                <div className={style.feturesdata}>
                  <img src={circle} className={style.circle} alt="Circle" />
                  <img
                    src={hovercircle}
                    className={style.hovercircle}
                    alt="Hover Circle"
                  />
                  <span key={index}>
                    <p>{item.text}</p>
                  </span>
                </div>
              </>
            ))}
          </div>
        )}

        {isMorePlans && (
          <div
            className={style.isMorePlansButton}
            onClick={() => toggleBussinessCards()}
          >
            <div className={style.arrow}></div>
          </div>
        )}
      </div>
    </div>
  );
};

Card.defaultProps = {
  isEnterprise: false,
};

Card.propTypes = {
  subtitle: PropTypes.arrayOf.isRequired,
  imgUrl: PropTypes.string.isRequired,
  isEnterprise: PropTypes.bool,
  isStarter: PropTypes.bool,
  planId: PropTypes.string,
  title: PropTypes.arrayOf.isRequired,
  name: PropTypes.string.isRequired,
  selectedlawsnumber: PropTypes.number,
  oneprivacypriceusd: PropTypes.number,
  twoprivacypriceusd: PropTypes.number,
  threeprivacypriceusd: PropTypes.number,
  oneprivacypriceeur: PropTypes.number,
  twoprivacypriceeur: PropTypes.number,
  threeprivacypriceeur: PropTypes.number,
  condition: PropTypes.arrayOf.isRequired,
  bottomClarification: PropTypes.arrayOf.isRequired,
  planBenefits: PropTypes.arrayOf.isRequired,
  checkFeaturesOnCard: PropTypes.arrayOf.isRequired,
  description: PropTypes.arrayOf.isRequired,
  buttonText: PropTypes.arrayOf.isRequired,
  buttonLink: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  isAnnual: PropTypes.bool.isRequired,
  isMorePlans: PropTypes.bool.isRequired,
  annualcoefficient: PropTypes.number.isRequired,
  selectedPlans: PropTypes.arrayOf.isRequired,
  isMobile: PropTypes.bool.isRequired,
  enterpriseCondition: PropTypes.string.isRequired,
  enterpriseButtonText: PropTypes.string.isRequired,
  enterpriseButtonLink: PropTypes.string.isRequired,
  toggleBussinessCards: PropTypes.any,
};

export default Card;
