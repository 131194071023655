import React from 'react';
import PropTypes from 'prop-types';
import style from './CardData.module.scss';
import Card from '../Card/Card';

const CardData = ({ items }) => {
  // const { buttonlink, buttontext } = primary;

  return (
    <div className={style.container}>
      <div className={style.cards}>
        {items?.map((item, index) => (
          <Card
            key={index}
            title={item.title}
            subtitle={item.subtitle}
            // enterpriseCondition={primary.enterprisecondition.text}
            // enterpriseButtonText={primary.enterprisebuttontext.text}
            // enterpriseButtonLink={primary.enterprisebuttonlink.url}
            bottomClarification={item.bottom_clarification}
            planBenefits={item.plan_benefits}
            imgUrl={item.img?.url}
            checkFeaturesOnCard={item.check_features_on_card}
            // toggleBussinessCards={toggleBussinessCards}
          />
        ))}
      </div>
    </div>
  );
};

CardData.propTypes = {
  primary: PropTypes.arrayOf.isRequired,
  items: PropTypes.arrayOf.isRequired,
};

export default CardData;
